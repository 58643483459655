import React from 'react'

const DownloadListItem = ({
  title,
  link,
  ariaLabel,
  onDownload,
  type,
  size,
}: {
  title?: string
  link: string
  type?: string
  size?: string
  ariaLabel?: string
  onDownload: () => void
}): JSX.Element => {
  return (
    <a className="cmp-download-list__action-wrapper" href={link} onClick={onDownload}>
      <div className="cmp-download-list__item">
        <div className="cmp-download-list__content">
          <p className="cmp-download-list__title">
            {title}
            <small className="cmp-download-list__file-info">
              {type}
              {size && <span> ({size})</span>}
            </small>
          </p>
          <div className="cmp-download-list__action">
            {/* eslint-disable-next-line jsx-a11y/anchor-has-content */}
            <a
              aria-label={ariaLabel || title}
              className="cmp-download-list__action-label"
              href={link}
              onClick={onDownload}
            />
          </div>
        </div>
      </div>
    </a>
  )
}

export default DownloadListItem
