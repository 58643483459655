// eslint-disable-next-line sonarjs/cognitive-complexity
;(function () {
  'use strict'

  function onDocumentReady() {
    const tabTeasersContainer = document.querySelectorAll('.cmp-tabs-teaser')
    for (const tabTeaser of tabTeasersContainer) {
      const tabs = tabTeaser.querySelectorAll('.cmp-tabs-teaser__tabs__tab')
      const contentSections = tabTeaser.querySelectorAll('.cmp-tabs-teaser__content')

      for (const [index, tab] of tabs.entries()) {
        tab.addEventListener('click', () => {
          for (const t of tabs) t.classList.remove('cmp-tabs-teaser__tabs__tab--is-active')
          tab.classList.add('cmp-tabs-teaser__tabs__tab--is-active')

          for (const section of contentSections) {
            const contentTabs = section.querySelectorAll('.cmp-tabs-teaser__content__tab')
            for (const contentTab of contentTabs)
              contentTab.classList.remove('cmp-tabs-teaser__content__tab--is-active')
            if (contentTabs[index]) {
              contentTabs[index].classList.add('cmp-tabs-teaser__content__tab--is-active')
            }
          }
        })
      }
    }
  }

  if (document.readyState !== 'loading') {
    onDocumentReady()
  } else {
    document.addEventListener('DOMContentLoaded', onDocumentReady)
  }
})()
