import React from 'react'
import { AEMLinkProperties } from '@components/Form/FormTypes'

type StoreEntityCSVProperties = {
  title: string
  name: string
  street?: string
  location?: string
  phone?: string
  email?: string
  appointmentLabel?: string
  fallbackLink?: AEMLinkProperties
  fallbackLinkLabel?: string
}

export const StoreEntityCSV: React.FC<StoreEntityCSVProperties> = ({
  title,
  name,
  street,
  location,
  phone,
  email,
  appointmentLabel,
  fallbackLink,
  fallbackLinkLabel,
}): JSX.Element => {
  return (
    <div className="cmp-store-locator__entity">
      <h3 className="cmp-store-locator__entity-title">{title}</h3>
      <h5 className="cmp-store-locator__entity-name">{name}</h5>
      {(street || location) && (
        <p className="cmp-store-locator__entity-address">
          {street}, {location}
        </p>
      )}
      {phone && (
        <div className="cmp-store-locator__entity-contact">
          <i className="ri-smartphone-line" />
          <a className="cmp-store-locator__entity-contact-tel" href={`tel:${phone}`}>
            {phone}
          </a>
        </div>
      )}
      {email && (
        <div className="cmp-store-locator__entity-contact">
          <i className="ri-mail-line" />
          <span>{email}</span>
        </div>
      )}
      {appointmentLabel && <p className="cmp-store-locator__entity-appointment">{appointmentLabel}</p>}

      {!email && fallbackLink && fallbackLinkLabel && (
        <a
          className="cmp-store-locator__entity-fallbackLink"
          href={fallbackLink?.externalizedURL}
          target="_blank"
          rel={fallbackLink?.htmlAttributes?.rel}
        >
          {fallbackLinkLabel} <i className="ri-arrow-right-line" />
        </a>
      )}
    </div>
  )
}
