import { FC } from 'react'
import { FieldErrors, FieldValues, useForm, UseFormRegister } from 'react-hook-form'
import ResponsiveImage from '@components/ResponsiveImage'
import { useFetchError, useFetchSuccess, usePOSTFormData } from '@hooks/fetch'
import { cls } from '@utils/classnames'
import sanitizeHtml from 'sanitize-html'

import { HeaderTag } from '../FormTypes'
import { FormContentModel, FormFieldDefinition } from '../FormTypes'

interface FormContentProperties {
  fieldConfig: FormContentModel
  endpointUrl?: {
    formEndpointUrl?: string
  }
  className?: string
  hideImage?: boolean
  recaptchaElementId?: string
}

function pushMailingListSubscriptionEvent(status: 'success' | 'failed') {
  if (window.adobeDataLayer) {
    window.adobeDataLayer.push({
      event: 'submit newsletter',
      eventInfo: {
        reference: 'floater sign up',
        status,
      },
    })
  }
}

const pushMailingListSubscriptionSuccessEvent = () => pushMailingListSubscriptionEvent('success')
const pushMailingListSubscriptionFailedEvent = () => pushMailingListSubscriptionEvent('failed')

const emailPattern = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/

const FormContent: FC<FormContentProperties> = ({
  fieldConfig,
  className,
  endpointUrl,
  hideImage,
  recaptchaElementId,
}) => {
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm({ reValidateMode: 'onSubmit' })

  const [postState, sendPost] = usePOSTFormData({
    url: endpointUrl?.formEndpointUrl || '',
  })

  const sendForm = (formDataAsJson: Record<string, string | boolean>) => {
    if (postState.status !== 'PENDING') {
      const mainElement = document.querySelector<HTMLElement>(`#${recaptchaElementId}`)
      if (!recaptchaElementId || !mainElement) {
        console.warn('id is missing or wrong, cannot get token')

        return
      }

      const dataRecaptchaToken = mainElement.dataset.recaptchaToken || ''

      sendPost({ ...formDataAsJson, 'g-recaptcha-response': dataRecaptchaToken })
    }
  }

  useFetchSuccess(postState, pushMailingListSubscriptionSuccessEvent)
  useFetchError(postState, pushMailingListSubscriptionFailedEvent)

  const HeaderTag = fieldConfig.formTitleHeadingLevel as HeaderTag

  return (
    <div
      className={cls({
        'cmp-form-content': true,
        [className || '']: className,
      })}
    >
      <div
        className={cls({
          'cmp-form-content__slide-area': true,
          'cmp-form-content__slide-area--is-success': postState.status === 'SUCCESS',
        })}
      >
        {fieldConfig.teaserImage && !hideImage && (
          <div className="cmp-form-content__teaser-image-container">
            <div className="cmp-form-content__teaser-image">
              <ResponsiveImage
                src={fieldConfig.teaserImage?.originalImageUrl || fieldConfig.teaserImage?.imageUrl || ''}
                alt={fieldConfig.teaserImage?.altText || ''}
              />
            </div>
          </div>
        )}
        {postState.status !== 'SUCCESS' && (
          <div className="cmp-form-content__form-area">
            <HeaderTag className="cmp-form-content__title">{fieldConfig.formTitle}</HeaderTag>
            <form onSubmit={handleSubmit(sendForm)} className="cmp-form-content__form cmp-form">
              {fieldConfig.fields.map((field) => (
                <FormContentField key={field.name} errors={errors} field={field} register={register} />
              ))}
              <input
                type="submit"
                className="cmp-form-button"
                disabled={postState.status === 'PENDING'}
                value={fieldConfig.submitLabel}
              />

              {postState.status === 'ERROR' && (
                <div className="cmp-form-text cmp-form-text--is-invalid">
                  <span className="cmp-form-text__error-message">{fieldConfig.genericSubmitErrorLabel}</span>
                </div>
              )}
            </form>
          </div>
        )}
        {postState.status === 'SUCCESS' && (
          <div className="cmp-form-content__form-area">
            <h3 className="cmp-form-content__title">{fieldConfig.successTitle}</h3>
            <p className="cmp-form-content__success-message">{fieldConfig.successMessage}</p>
            {!hideImage && (
              <button type="submit" className="cmp-form-button">
                {fieldConfig.successButtonLabel}
              </button>
            )}
          </div>
        )}
      </div>
    </div>
  )
}

const FormContentField: FC<{
  field: FormFieldDefinition
  register: UseFormRegister<FieldValues>
  errors: FieldErrors<FieldValues>
}> = ({ field, register, errors }) => {
  if (field.type === 'checkbox') {
    return (
      <fieldset className="cmp-form-options cmp-form-options--checkbox">
        <label
          className={cls({
            'cmp-form-options__field-label': true,
            'cmp-form-options--is-invalid': Boolean(errors[field.name]),
          })}
        >
          <input
            className="cmp-form-options__field cmp-form-options__field--checkbox"
            type="checkbox"
            {...register(field.name, { required: field.required })}
          />
          <span className="cmp-form-options__field-description">
            <p
              dangerouslySetInnerHTML={{
                __html: sanitizeHtml(field.checkboxLabel || '', {
                  allowedTags: ['a'],
                }),
              }}
            ></p>
          </span>
          <span className="cmp-form-options__error-message">{field.errorLabel}</span>
        </label>
      </fieldset>
    )
  }

  if (field.type === 'text' || field.type === 'email') {
    return (
      <div
        className={cls({
          'cmp-form-text': true,
          'cmp-form-text--is-invalid': Boolean(errors[field.name]),
        })}
      >
        <label htmlFor={field.name}>
          {field.label}
          {field.required ? <span>*</span> : null}
        </label>
        <input
          id={field.name}
          className="cmp-form-text__text"
          placeholder={field.placeholder}
          maxLength={field.maxLength}
          {...register(field.name, {
            required: field.required,
            pattern: field.type === 'email' ? emailPattern : undefined,
          })}
        />
        <span className="cmp-form-text__error-message">{field.errorLabel}</span>
      </div>
    )
  }

  if (field.type === 'select') {
    return (
      <fieldset className="cmp-form-options cmp-form-options--drop-down">
        <label className="cmp-form-options__label" htmlFor={field.name}>
          {field.label} {field.required ? <span>*</span> : null}
        </label>
        <select
          className="cmp-form-options__field cmp-form-options__field--drop-down"
          {...register(field.name, { required: field.required })}
        >
          {field.dropdownOptions?.map((option) => {
            return (
              <option value={option.optionValue} key={option.optionValue}>
                {option.optionLabel}
              </option>
            )
          })}
        </select>
        <span className="cmp-form-options__error-message">{field.errorLabel}</span>
      </fieldset>
    )
  }

  return null
}

export default FormContent
