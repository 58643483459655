import { isMobile } from '../../utils/mediaQueries'
// eslint-disable-next-line sonarjs/cognitive-complexity
;(function () {
  'use strict'

  function onDocumentReady() {
    const animatedTeasers = document.querySelectorAll('.cmp-animated-teaser')

    if (animatedTeasers.length > 0) {
      const observer = new IntersectionObserver(
        (entries, observer) => {
          for (const entry of entries) {
            if (entry.isIntersecting) {
              const animatedTeaser = entry.target
              const animatedTeaserImage = animatedTeaser.querySelector('.cmp-animated-teaser__image--animated')
              const animatedTeaserContent = animatedTeaser.querySelector('.cmp-animated-teaser__content')

              if (animatedTeaserImage && animatedTeaserContent) {
                animatedTeaser.classList.add('cmp-animated-teaser--has-animation')

                if (isMobile()) {
                  requestAnimationFrame(() => {
                    animatedTeaser.style.height = `${animatedTeaserContent.scrollHeight}px`
                  })
                }

                setTimeout(() => {
                  animatedTeaserImage.classList.add('cmp-animated-teaser__image--has-scale')
                }, 2000)

                observer.unobserve(animatedTeaser) // Stop observing once animated
              }
            }
          }
        },
        { threshold: 0, rootMargin: '0px 0px -30% 0px' },
      )

      for (const teaser of animatedTeasers) observer.observe(teaser)
    }
  }

  if (document.readyState !== 'loading') {
    onDocumentReady()
  } else {
    document.addEventListener('DOMContentLoaded', onDocumentReady)
  }
})()
