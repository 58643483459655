// eslint-disable-next-line sonarjs/cognitive-complexity
;(function () {
  'use strict'
  function onDocumentReady() {
    const imageTabsComponents = document.querySelectorAll('.cmp-image-tabs')
    if (!imageTabsComponents || imageTabsComponents.length === 0) return

    for (const imageTabsComponent of imageTabsComponents) {
      const tabs = imageTabsComponent.querySelectorAll('.cmp-image-tabs__tab')
      const panels = imageTabsComponent.querySelectorAll('.cmp-image-tabs__tab-panel')
      const scrollContainer = imageTabsComponent.querySelector('.cmp-image-tabs__scroll-container')
      const imageWrapper = imageTabsComponent.querySelector('.cmp-image-tabs__image-wrapper')
      const imageSlider = imageTabsComponent.querySelector('.cmp-image-tabs__slider')
      const sliderThumb = imageTabsComponent.querySelector('.cmp-image-tabs__slider-thumb')

      const tabActiveClass = 'cmp-image-tabs__tab--active'
      const tabPanelActiveClass = 'cmp-image-tabs__tab-panel--active'

      let isDragging = false

      for (const [index, tab] of tabs.entries()) {
        tab.dataset.index = index
      }

      tabs[0]?.classList.add(tabActiveClass)
      panels[0]?.classList.add(tabPanelActiveClass)

      for (const tab of tabs) {
        tab.addEventListener('click', () => {
          imageTabsComponent.querySelector(`.${tabActiveClass}`).classList.remove(tabActiveClass)
          imageTabsComponent.querySelector(`.${tabPanelActiveClass}`).classList.remove(tabPanelActiveClass)

          const index = tab.dataset.index
          tab.classList.add(tabActiveClass)
          panels[index].classList.add(tabPanelActiveClass)

          for (const t of tabs) t.setAttribute('aria-selected', 'false')
          for (const p of panels) p.setAttribute('hidden', 'true')

          tab.setAttribute('aria-selected', 'true')
          panels[index].removeAttribute('hidden')
        })
      }

      /* eslint-disable unicorn/consistent-function-scoping */
      function updateSliderPosition() {
        const scrollLeft = scrollContainer.scrollLeft
        const maxScroll = imageWrapper.scrollWidth - scrollContainer.clientWidth
        const maxThumbPosition = imageSlider.clientWidth - sliderThumb.clientWidth

        let thumbLeft = (scrollLeft / maxScroll) * maxThumbPosition
        sliderThumb.style.left = `${Math.max(0, Math.min(thumbLeft, maxThumbPosition))}px`
      }

      function startDrag(event) {
        isDragging = true
        event.preventDefault()
      }

      function onDrag(event) {
        if (!isDragging) return

        let clientX = event.touches ? event.touches[0].clientX : event.clientX
        let rect = imageSlider.getBoundingClientRect()
        let newLeft = clientX - rect.left

        const maxLeft = imageSlider.clientWidth - sliderThumb.clientWidth
        newLeft = Math.max(0, Math.min(newLeft, maxLeft))

        if (Math.abs(Number.parseFloat(sliderThumb.style.left || 0) - newLeft) > 0.5) {
          sliderThumb.style.left = `${newLeft}px`
          const scrollMax = imageWrapper.scrollWidth - scrollContainer.clientWidth
          scrollContainer.removeEventListener('scroll', updateSliderPosition) // Temporarily remove scroll listener
          scrollContainer.scrollLeft = (newLeft / maxLeft) * scrollMax
          requestAnimationFrame(() => {
            scrollContainer.addEventListener('scroll', updateSliderPosition) // Restore listener after scroll update
          })
        }
      }

      function stopDrag() {
        isDragging = false
      }
      /* eslint-enable unicorn/consistent-function-scoping */

      scrollContainer.addEventListener('scroll', updateSliderPosition)
      sliderThumb.addEventListener('mousedown', startDrag)
      sliderThumb.addEventListener('touchstart', startDrag)
      imageTabsComponent.addEventListener('mousemove', onDrag)
      imageTabsComponent.addEventListener('touchmove', onDrag)
      imageTabsComponent.addEventListener('mouseup', stopDrag)
      imageTabsComponent.addEventListener('touchend', stopDrag)
    }
  }

  if (document.readyState !== 'loading') {
    onDocumentReady()
  } else {
    document.addEventListener('DOMContentLoaded', onDocumentReady)
  }
})()
